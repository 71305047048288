.Spinner_Loader__2OGMu,
.Spinner_Loader__2OGMu:before,
.Spinner_Loader__2OGMu:after {
  border-radius: 50%;
}
.Spinner_Loader__2OGMu {
  color: #fff;
  font-size: 11px;
  text-indent: -99999em;
  margin: 55px auto;
  position: relative;
  width: 10em;
  height: 10em;
  box-shadow: inset 0 0 0 1em;
  transform: translateZ(0);
}
.Spinner_Loader__2OGMu:before,
.Spinner_Loader__2OGMu:after {
  position: absolute;
  content: '';
}
.Spinner_Loader__2OGMu:before {
  width: 5.2em;
  height: 10.2em;
  background: #2E2E2E;
  border-radius: 10.2em 0 0 10.2em;
  top: -0.1em;
  left: -0.1em;
  transform-origin: 5.2em 5.1em;
  -webkit-animation: Spinner_load2__2zqAR 2s infinite ease 1.5s;
  animation: Spinner_load2__2zqAR 2s infinite ease 1.5s;
}
.Spinner_Loader__2OGMu:after {
  width: 5.2em;
  height: 10.2em;
  background: #2E2E2E;
  border-radius: 0 10.2em 10.2em 0;
  top: -0.1em;
  left: 5.1em;
  transform-origin: 0px 5.1em;
  -webkit-animation: Spinner_load2__2zqAR 2s infinite ease;
  animation: Spinner_load2__2zqAR 2s infinite ease;
}
@-webkit-keyframes Spinner_load2__2zqAR {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes Spinner_load2__2zqAR {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.notice-bar {
  width: 100%;
  max-width: 1125px;
  position: sticky;
  bottom: 0;
  padding: 0;
  right: 6px;
  margin: 20px auto 0px auto;
  color: #ccc;
  font-size: 11px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

@media screen and (min-width: 1800px) {
  .notice-bar {
    max-width: 1340px;
  }
}
.footer {
  width: 100%;
  max-width: 1125px;
  position: relative;
  bottom: 0;
  height: 80px;
  background-color: #232931;
  padding: 0;
  right: 6px;
  margin: 20px auto 0px auto;
  color: #ccc;
  font-size: 11px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.footer .community{
  margin-top: 5px;
}

@media screen and (min-width: 1800px) {
  .footer {
    max-width: 1340px;
  }
}
.NewRecommendation_select__31a9u {
    color: #000 !important;
}

.NewRecommendation_select__31a9u option {
    color: #000 !important;
}
.nav-tabs .nav-link.active {
    background-color: rgb(46, 46, 46) !important;
}
.AnimeSearch_searchBox__37bXg {
    min-height: 400px;
}
.AnimeSearch_dropdown__1JSoe {
    max-width: 190px;
    max-height: 30px;
}
.Item_searchBox__12zmS {
    min-height: 420px;
}
.Item_scrollable__2F0fx{
    overflow: scroll;
    height: 240px;
    font-size: 0.75em;
}
.Item_title__fa4Iy {
    min-height: 35px;
    vertical-align: middle;
    line-height: normal;
    font-size: 1em;
    text-align: center;
}

.Item_genres__3b4t2 {
    min-height: 53px;
    max-height: 53px; 
    padding-bottom: 5px;
    overflow: hidden;
}

.Item_image__1vm_H {
    max-height: 240px;
    width: 50%;
    float: left;
    margin: 5px;
    max-width: 180px;
}

.Item_views__2d0VY{
    border-right:10px solid #2E2E2E;
    background-color: #777;
    font-size: 12px;
    margin-top:2px;
}
.Item_score__2C8v6{
    background-color: #777;
    font-size: 12px;
    margin-top:2px;
}
.Item_year__1XbdU{
    background-color: #777;
    font-size: 12px;
    margin-top:2px;
}
