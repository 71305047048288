.notice-bar {
  width: 100%;
  max-width: 1125px;
  position: sticky;
  bottom: 0;
  padding: 0;
  right: 6px;
  margin: 20px auto 0px auto;
  color: #ccc;
  font-size: 11px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

@media screen and (min-width: 1800px) {
  .notice-bar {
    max-width: 1340px;
  }
}