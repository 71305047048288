.searchBox {
    min-height: 420px;
}
.scrollable{
    overflow: scroll;
    height: 240px;
    font-size: 0.75em;
}
.title {
    min-height: 35px;
    vertical-align: middle;
    line-height: normal;
    font-size: 1em;
    text-align: center;
}

.genres {
    min-height: 53px;
    max-height: 53px; 
    padding-bottom: 5px;
    overflow: hidden;
}

.image {
    max-height: 240px;
    width: 50%;
    float: left;
    margin: 5px;
    max-width: 180px;
}

.views{
    border-right:10px solid #2E2E2E;
    background-color: #777;
    font-size: 12px;
    margin-top:2px;
}
.score{
    background-color: #777;
    font-size: 12px;
    margin-top:2px;
}
.year{
    background-color: #777;
    font-size: 12px;
    margin-top:2px;
}