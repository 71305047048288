.footer {
  width: 100%;
  max-width: 1125px;
  position: relative;
  bottom: 0;
  height: 80px;
  background-color: #232931;
  padding: 0;
  right: 6px;
  margin: 20px auto 0px auto;
  color: #ccc;
  font-size: 11px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.footer .community{
  margin-top: 5px;
}

@media screen and (min-width: 1800px) {
  .footer {
    max-width: 1340px;
  }
}